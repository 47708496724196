import React, {useState} from 'react';
import './DetailsPrompt.css'
import {useHistory, useParams} from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function DetailsPrompt(props) {
  let [ email,   setEmail   ] = useState("");
  let [ name,    setName    ] = useState("");
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();
  let { roomName } = useParams();

  const cookies = new Cookies();
  let cookieOptions = {path: '/', maxAge: 60*60*24, sameSite: "lax"};
  // information caching will only last for one day
  // to encourage re-entry for different natgen sessions

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    cookies.set('participantEmail', email, cookieOptions);
    cookies.set('participantName', name, cookieOptions);
    history.push(`/room/${roomName}`)
  }

  return (
    <main id="detailsPrompt">
      <h1>Join the Meeting</h1>
      <form id="detailsForm" onSubmit={handleSubmit}>
        <label htmlFor="emailInput" form="detailsForm">Email Address</label>
        <input id="emailInput" type="email" name="email" placeholder="example@deepgram.com" value={email} onChange={e => setEmail(e.target.value)}/>
        <label htmlFor="passwordInput" form="detailsForm">Full Name</label>
        <input id="nameInput" name="name" placeholder="Full Name" value={name} onChange={e => setName(e.target.value)}/>
        <button type="submit" disabled={loading}>{loading ? "Loading..." : "Join"}</button>
      </form>
      <div className="disclaimer">By joining this meeting, you agree to Deepgram's <a href="https://static.deepgram.com/business/Business_TOS.pdf">Terms of Service</a> and <a href="https://www.iubenda.com/privacy-policy/88905781">Privacy Policy</a>. This meeting will be recorded.</div>
    </main>
  )
}