import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import logo from './assets/logo.svg';
import AppRenderer from './App';
import ExitMeeting from './ExitMeeting';
import HomePageRenderer from './HomePage';
import CreateRoom from './CreateRoom';
import DetailsPrompt from './DetailsPrompt';
import * as serviceWorker from './serviceWorker';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <img src={logo} id="logo" alt=""/>
    <Router>
      <Switch>
        <Route exact path="/" component={HomePageRenderer}/>
        <Route exact path="/create/:datasetID" component={CreateRoom}/>
        <Route exact path="/exit/:roomName" component={ExitMeeting}/>
        <Route exact path="/room/:roomName" component={AppRenderer}/>
        <Route exact path="/enter/:roomName" component={DetailsPrompt}/>
        <Route>
          <Redirect to='/'/>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
