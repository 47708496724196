import React from 'react';

import ManagementForm from './ManagementForm'

export default function HomePageRenderer() {
  return (
    <main>
      <ManagementForm/>
    </main>
  )
}
