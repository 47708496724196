import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import './ManagementForm.css'

export default function ManagementForm(props) {
  let [ username,  setUsername  ] = useState("");
  let [ password,  setPassword  ] = useState("");
  let [ company,   setCompany   ] = useState(null);
  let [ dataset,   setDataset   ] = useState(null);
  let [ auth,      setAuth      ] = useState(false);
  let [ companies, setCompanies ] = useState([]);
  let [ datasets,  setDatasets  ] = useState([]);
  let [ authError, setAuthError ] = useState(false);
  let [ loading,   setLoading   ] = useState(false);

  let history = useHistory();

  const apiConfig = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    auth: {username, password},
    // this automatically functions as a getter!
  };

  const createDataset = name => {
    if (window.confirm("Are you sure you want to create a new dataset?")) {
      axios.post('/datasets', {"name": name, "company": company.value}, apiConfig)
      .then(res => {
        console.log("Dataset created")
        const newSet = {value: res.data.uuid, label: res.data.tag}
        setDatasets([...datasets, newSet]);
        setDataset(newSet);
      })
      .catch(err => {
        console.error("Dataset creation failed!")
        setDataset(null);
      });
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (!username || !password) {
      console.error("No auth given")
      return false;
    }

    if (!dataset) { 
      if (!auth) { // only login visible
        setAuthError(false);
        setLoading(true);
        axios.get('/auth', apiConfig)
        .then(res => { // auth success - failures are marked with status codes
          return axios.get('/companies', apiConfig);
        }).then(res => {
          console.log(res.data);
          // only load the list of companies once we have confirmed auth
          setCompanies(res.data.map(obj => ({value: obj.uuid, label: obj.tag})));
          setAuth(true);
          setLoading(false);
        }).catch(err => {
          console.error(err);
          setAuthError(true);
          setLoading(false);
          return false;
        })
      } else {
        console.error("Dataset not specified!")
        // TODO: Clarify in UI
        return false;
      }
    } else { // actual submission
      setLoading(true);
      axios.post('/keys', {"dataset": dataset.value}, apiConfig)
      .then(res => {
        history.push(`/create/${dataset.value}?auth=${res.data}`);
      }).catch(err => {
        console.error("Key creation failed!")
        setLoading(false);
        return false;
      })
    }
  }

  const objArrayToCSV = arr => {
    if (!arr[0]) return ""; // no data!
    const header = Object.keys(arr[0])
    const itemProcess = item => {
      if (typeof item === "string") {
        return item;
      } else {
        let out = JSON.stringify(item);
        if (Array.isArray(item)) {
          out = out.replaceAll("|", "\\|")
          out = out.replaceAll(",", "|"); // replace commas with pipes and escape pipes
        }
        return out;
      }
    }
    const lines = arr.map(obj => header.map(field => itemProcess(obj[field])).join(","));
    return [header, ...lines].join("\n");
  }

  const fetchReport = () => {
    axios.get(`/dataset/${dataset.value}/report`, apiConfig)
    .then(res => {
      let filename = `${dataset.label}.csv`;
      let contentType = "text/csv;charset=utf-8;";
      // let rawText = JSON.stringify(res.data, null, 2)
      // we're using CSVs instead
      let rawText = objArrayToCSV(res.data);
      if (!rawText) {
        window.alert("No data to report on!")
      } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(rawText))], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(rawText);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    }).catch(err => {
      console.error("Couldn't get report!")
    })
  }

  const selectStyling = {
    container: (provided) => ({
      ...provided,
      height: "2.5em",
      marginBottom: "1.5em",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "2.5em",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#0a121b",
      border: "1px solid #999",
      height: "2.5em",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#0a121b",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#121a23" : "#0a121b",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
  }

  const companyChange = val => {
    setCompany(val);
    setLoading(true);
    console.log("Fetching datasets")
    axios.get(`/datasets?company=${val.value}`, apiConfig).then(res => {
      setDatasets(res.data.map(obj => ({value: obj.uuid, label: obj.tag})));
      setLoading(false);
    }).catch(err => {
      console.error(err);
      setLoading(false);
      return false;
    })
  }

  return (
    <>
    <h3>DeepGram Admin Portal</h3> 
    <h1>{auth ? "Create a Project" : "Login to Talkflight"}</h1>
    <form id="manageForm" onSubmit={handleSubmit}>
      <div id="loginBlock" style={{display: auth ? 'none' : 'flex'}}>
        <label htmlFor="usernameInput" form="manageForm">Email Address</label>
        <input id="usernameInput" name="username" type="email" placeholder="example@deepgram.com" value={username} onChange={e => setUsername(e.target.value)}/>

        <label htmlFor="passwordInput" form="manageForm">Password</label>
        <input type="password" id="passwordInput" name="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
      </div>
      {auth ? (
        <>
        <label htmlFor="companySelect" form="manageForm" style={{"fontSize": "0.8em"}}>Which company should we save the recordings in?</label>
        <Select
          defaultValue={company}
          value={company}
          placeholder={"datagen"}
          onChange={companyChange}
          options={companies}
          styles={selectStyling}
          name="companySelect"
          id="companySelect"
        />
        <label htmlFor="datasetSelect" form="manageForm" style={{"fontSize": "0.8em"}}>
          Which dataset should we save the recordings in? <div className="material-icons" style={{"fontSize": "100%"}} data-tip='New dataset names must include "natgen" or "datagen"'>info</div>
          <ReactTooltip/>
        </label>
        <CreatableSelect
          defaultValue={dataset}
          value={dataset}
          placeholder={"hop-natgen-myproject"}
          onChange={setDataset}
          options={datasets}
          isClearable={true}
          onCreateOption={createDataset}
          formatCreateLabel={name => (<span style={{color: "#fb3640", fontWeight: "bold", textTransform: "uppercase"}}>{`Create "${name}" +`}</span>)}
          styles={selectStyling}
          name="datasetSelect"
          id="datasetSelect"
          isDisabled={loading || !company}
          isLoading={loading}
          isValidNewOption={name => (name.toLowerCase().includes("natgen") || name.toLowerCase().includes("datagen"))}
        />
        <button type="submit" disabled={!(dataset?.label) || loading}>{loading ? "Loading..." : "Create Project Link"}</button>
        <button type="button" disabled={!(dataset?.label)} onClick={fetchReport} className="secondaryButton">Download Report</button>
        </>
      ) : (
        <>
        <button type="submit" disabled={!username || !password || loading}>{loading ? "Loading..." : "Login"}</button>
        {authError ? <span className="error">Login failed!</span> : ""}
        <div className="disclaimer">This application is for registered Deepgram admins. If you do not yet have an account, speak with Deepgram Data Operations.</div>
        </>
      )}
    </form>
    </>
  )
}