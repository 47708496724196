import React from 'react';
import './App.css';
import DailyIframe from '@daily-co/daily-js';
import { useParams, Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

export default function AppRenderer() {
  let { roomName } = useParams();

  return (
    <App roomName={roomName} />
  )
}

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showConnectionDialog: true,
      invalidRoom: false,
      copied: false,
    }
  }

  componentWillMount() {
    const cookies = new Cookies();

    // check if there is a room marked in url parameters
    if(this.props.roomName != null) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/rooms/${this.props.roomName}`)
      .then((res) => {
        console.log(res)
        if(res.data.error != null) {
          this.setState({invalidRoom: true});
        } else {
          console.log('room exists', res.data)
          if (!(cookies.get('participantName') && cookies.get('participantEmail'))) {
            window.location.href = `/enter/${this.props.roomName}`;
          }
          this.callFrame = DailyIframe.createFrame(
            document.getElementById('call-frame-container'), {
              showLeaveButton: false,
            }
          );
          this.callFrame.on('left-meeting', (evt) => {
            this.onExitMeeting()
          })
          this.callFrame.on('joined-meeting', (evt) => {
            console.log(`joined - ${Object.keys(this.callFrame.participants()).length} participants`)
            if (Object.keys(this.callFrame.participants()).length === 1) {
              this.callFrame.startRecording()
            }
          })
          this.callFrame.join({ url: res.data.url });
          window.history.replaceState(null, null, `/room/${res.data.name}`);
          this.setState({ room: res.data })
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({invalidRoom: true});
      });
    } else {
      this.setState({invalidRoom: true});
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.tabCloseHandler.bind(this));
    this.participantNotify();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.tabCloseHandler.bind(this));
  }

  participantNotify() {
    const cookies = new Cookies();

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/rooms/${this.props.roomName}/participants`,
      {
        'name': cookies.get('participantName'),
        'email': cookies.get('participantEmail'),
      }
    ).catch(err => {console.error("Participant could not be recorded!"); console.error(err)});
  }

  tabCloseHandler(e) {
    console.log(`tab close - ${Object.keys(this.callFrame.participants()).length} participants`)
    if (Object.keys(this.callFrame.participants()).length === 1) {
      this.callFrame.stopRecording()
    }
  }

  onExitMeeting() {
    console.log(`exit - ${Object.keys(this.callFrame.participants()).length} participants`)
    if (Object.keys(this.callFrame.participants()).length === 1) {
      this.callFrame.stopRecording()
    }
    window.open(`/exit/${this.state.room.name}`, "_self");
  }

  onCopyText(e) {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    this.setState({copied: true});
    window.setTimeout(() => {this.setState({copied: false})}, 3000);
  }

  renderConnectionDetailsDialog() {
    if(this.state.showConnectionDialog && this.state.room && this.state.room.dialInPin) {
      return (
        <div style={{position: 'absolute', border: '1px solid white', fontSize: 12, padding: 3, textAlign: 'left', borderRadius: '4px', backgroundColor: "#161e28", left: '4px', top: '4px'}}>
          URL: {window.location.href}
          <br/>
          Number: +1 415-212-4409
          <br/>
          PIN: {this.state.room.dialInPin}
          <br/><br/>
          <div style={{ float: 'left', display: 'block', fontSize: 14 }}>
            <button onClick={this.onCopyText.bind(this)} style={{ marginRight: 10 }} className="link-button">{this.state.copied ? "Copied!" : "Copy text"}</button>
            <button onClick={() => {this.setState({ showConnectionDialog: false})}} className="link-button">Hide</button>
          </div>
          <textarea 
            style={{ zIndex: -10, height: 0, width: 0, maxHeight: 0, maxWidth: 0, fontSize: 1, position: 'absolute' }}
            ref={(textarea) => this.textArea = textarea}
            value={`URL: ${window.location.href} \n Number: +1 415-212-4409 \n PIN: ${this.state.room.dialInPin}`}
            readOnly
          />            
        </div>
      )
    } else {
      return (     
        <div style={{position: 'absolute', border: '1px solid white', fontSize: 12, padding: 3, textAlign: 'left', borderRadius: '4px', backgroundColor: "#161e28", left: '4px', top: '4px'}}>
          <div style={{ float: 'left', display: 'block', fontSize: 14 }}>
            <button onClick={() => {this.setState({ showConnectionDialog: true })}} style={{color: "#ccf"}} className="link-button">Connection Details</button>
          </div>  
        </div>
      )
    }
  }

  renderRedirect = () => this.state.invalidRoom ? <Redirect to='/'/> : "";

  render(){
    return (
      <div className="App">
        {this.renderRedirect()}
        {this.renderConnectionDetailsDialog()}
        <div style={{ height: '100vh' }} id={'call-frame-container'}>
        </div>
        <div style={{position: 'fixed', bottom: 0, background: 'black', height: "32px", minWidth: '100%', border: '1px solid black'}}>
          <button style={{padding: "0em 1em", background: '#FB3640'}} onClick={this.onExitMeeting.bind(this)}>
            Leave Meeting
          </button>          
        </div> 
      </div>
    );
  }
}
