import React, {useState, useEffect, useMemo} from 'react';
import './CreateRoom.css'
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { MdContentCopy } from "react-icons/md";

export default function CreateRoom(props) {
  const [ datasetName, setDatasetName ] = useState("Loading...");
  const [ createdRoom, setCreatedRoom ] = useState({});
  const [ created,     setCreated     ] = useState(false);
  const [ copied,      setCopied      ] = useState(false);
  const [ loading,     setLoading     ] = useState(true);

  const history = useHistory();

  const { datasetID } = useParams();
  const { auth } = queryString.parse(props.location.search);

  const apiConfig = useMemo(() => ({
    baseURL: process.env.REACT_APP_BACKEND_URL
  }), []);

  useEffect(() => {
    axios.get(`/dataset/${datasetID}?auth=${auth}`, apiConfig)
    .then(res => {
      console.log("Authorization successful")
      setDatasetName(res.data.tag);
      setLoading(false);
    }).catch(err => {
      console.error(`Authorization failed: ${err}`);
      history.push('/');
    });
  }, [datasetID, auth, apiConfig, history]);

  const roomCreate = () => {
    setLoading(true);
    axios.post(`/rooms?auth=${auth}`, {dataset: datasetID}, apiConfig)
    .then(res => {
      setCreatedRoom(res.data);
      setLoading(false);
      setCreated(true);
      setCopied(false);
    }).catch(err => {
      console.error("Couldn't create room!")
      setLoading(false);
    });
  }

  const copyLabelUpdate = () => {
    setCopied(true);
    window.setTimeout(() => {setCopied(false)}, 3000);
  }

  return (
    <main id="createRoom">
      <h3>{datasetName}</h3> 
      <h1>{created ? "Room Created!" : "Create a Meeting Room"}</h1>
      { created ? ( <>
        <div className="roomInfo">
          URL: {`${window.location.origin}/room/${createdRoom.name}`}<br/>
          Number: +1 415-212-4409<br/>
          PIN: {createdRoom.dialInPin}
        </div>
        <button onClick={() => {
          navigator.clipboard.writeText(
            `URL: ${window.location.origin}/room/${createdRoom.name}\nNumber: +1 415-212-4409\nPIN: ${createdRoom.dialInPin}`
          )
          copyLabelUpdate();
        }}><MdContentCopy/> {copied ? "Copied!" : "Copy Room Info"}</button>
        <button className="secondaryButton" onClick={() => {history.push(`/room/${createdRoom.name}`)}}>Go to Meeting Room</button>
      </>) : (<>
        <button onClick={roomCreate} disabled={loading}>{loading ? "Loading..." : "Create Room"}</button>
        <button className="secondaryButton" onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          copyLabelUpdate();
        }}><MdContentCopy/> {copied ? "Copied!" : "Copy Project Link"}</button>
      </>)}
    </main>
  )
}