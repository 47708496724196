import React from 'react';
import { useParams } from 'react-router-dom';

export default function ExitMeetingRenderer() {
  let { roomName } = useParams();

  return (
    <ExitMeeting roomName={roomName} />
  )
}

class ExitMeeting extends React.Component {
  render() {
    return (
      <div>
        <h1>Thanks for Joining!</h1>
      </div>
    )
  }
}
